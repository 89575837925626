import React from 'react'
import './contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {GrLinkedin} from 'react-icons/gr'
import { useRef } from 'react'
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = event => {
    event.preventDefault();

    emailjs.sendForm('service_tpf9pr7', 'template_0qf5xad', form.current, '8rly7pGC4SOFpu8dN')
    .then(function(response) {
      console.log('SUCCESS!', response.status, response.text);
   }, function(error) {
      console.log('FAILED...', error);
   });
    event.target.reset()
  };

  return (
    <section id='contact'>
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            {/* <h5>jakub@skyforge.dev</h5> */}
            <a href="mailto:jakub@skyforge.dev" target="_blank" rel="noopener noreferrer">Send a message</a>
          </article>
          <article className="article contact__option">
            <GrLinkedin className='contact__option-icon'/>
            <h4>LinkedIn</h4>
            {/* <h5>jakub@skyforge.dev</h5> */}
            <a href="https://www.linkedin.com/in/devopskrk/" target="_blank" rel="noopener noreferrer">Reach Out</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
            <input type="text" name='name' placeholder='Your Full Name' required />
            <input type="email" name='email' placeholder='Your Email' required />
            <textarea name="message" rows="7" placeholder='Your Message' required ></textarea>
            <button type='submit' className='btn btn-primary'>Send Message</button>
          </form>
      </div>
    </section>
  )
}

export default Contact