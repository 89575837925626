import React from 'react'
import './footer.css'
// import  {IoLogoTwitter} from 'react-icons/io'
import {FaLinkedin} from 'react-icons/fa'
import {AiOutlineMail} from 'react-icons/ai'

const Footer = () => {
  return (
    <footer>
      <a href="/#" className='footer__logo'>SkyForge</a>

      <ul className='permalinks'>
        <li><a href="/#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    <div className="footer__socials">
      <a href="https://www.linkedin.com/in/devopskrk/"><FaLinkedin/></a>
      <a href="mailto:jakub@skyforge.dev"><AiOutlineMail/></a>
      {/* <a href="twitter"><IoLogoTwitter/></a> */}
    </div>

    <div className="footer__copyright">
      <small>
        &copy; Jakub Staśkiewicz - <a href="https://www.freepik.com/free-vector/system-maintenance-flat-set_26763675.htm#query=server%20rack&position=3&from_view=search&track=sph">Image by macrovector</a> on Freepik. Credits to <a href="https://www.buymeacoffee.com/egator">EGATOR</a> for great React guides.
      </small>
    </div>

    </footer>
  )
}

export default Footer