import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaTwitter} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/devopskrk/" target="_blank" rel="noopener noreferrer"><BsLinkedin /></a>
        {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a> */}
    </div>
  )
}

export default HeaderSocials