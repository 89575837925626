import React from 'react'
import "./services.css"
import {BiCheck} from 'react-icons/bi'

const Services = () => {
  return (
    <section id='services'>
      <h5>What do I offer</h5>
      <h2>Services</h2>

      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>Cloud Solutions</h3>
          </div>
          <ul className='service__list'>
          <li>
              <BiCheck className='service__list-icon' />
              <p>Cloud Automation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cost Optimization</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cloud Migration Services</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Security and Compliance</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Disaster Recovery</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>DevOps Engineering</h3>
          </div>
          <ul className='service__list'>
          <li>
              <BiCheck className='service__list-icon' />
              <p>CI/CD Setup and Monitoring</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Application Build Testing</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Service Scaling</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Infrastructure as Code (IaC)</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance Monitoring and Troubleshooting</p>
            </li>
          </ul>
        </article>
        <article className="service">
          <div className="service__head">
            <h3>Infrastructure Management
</h3>
          </div>
          <ul className='service__list'>
          <li>
              <BiCheck className='service__list-icon' />
              <p>Containerization and Orchestration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Performance Optimization</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Technology Integration</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Customized Automation Solutions</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Cloud-Native Consulting</p>
            </li>
          </ul>
        </article>
      </div>

    </section>
  )
}

export default Services