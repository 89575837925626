import React from 'react'
import './about.css'
import ME from '../../assets/me-about.png'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5>Get to know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>7+ years of DevOps Engineering experience, specializing in automation, CI/CD, and cloud solutions.</small><br></br><br></br>
              <small>SysAdmin: 12+ years, foundational expertise in systems and network management.</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>Application & Service Management</h5>
              <small>Currently orchestrating over 200 microservices and applications, employing advanced DevOps methodologies for optimal performance and reliability.</small><br></br><br></br>
              <small>Utilizing tools like Kubernetes, Docker, and Ansible for efficient scaling, deployment, and continuous integration and delivery.</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>Baremetal Servers in My Homelab</h5>
              <small>8+ and counting, hosting Proxmox and a variety of hypervisors for hands-on experimentation and learning.</small>
            </article>
          </div>

          <p>
          As a freelance DevOps expert, my journey began with an extensive foundation in System Administration. This early career phase ignited my ongoing enthusiasm for technology, especially in building and experimenting with Baremetal servers in my home lab. This personal venture is more than a hobby; it's a continual exploration of new technologies that directly enrich my freelance DevOps offerings. Whether it’s deploying a self-hosted Kubernetes cluster or masterfully utilizing Ansible for intricate configurations, I tackle each project with a blend of precision and innovation. My freelance work is characterized by this proactive approach to learning and applying cutting-edge DevOps solutions.
          </p>
          <a href="#contact" className='btn btn-primary'>Caught your interest? Let's Talk</a>
        </div>

      </div>
    </section>
  )
}

export default About

