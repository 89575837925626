import React from 'react'
// import CV from '../../assets/cv.pdf'

const CTA = () => {
  return (
    <div className='cta'>
        {/* <a href={CV} download className='btn'>CV</a> */}
        <a href="#contact" className='btn brn-primary'>Contact</a>
    </div>
  )
}

export default CTA